import appConfig from "constants/appConfig";

const cacheStorageUrls = [
    {
        url: `${appConfig.apis.ois.core.v1}Catalog?IncludeWebshops=true`,
        expireMinutes: 15, 
        prefetch: true   
    },
    {
        url: `${appConfig.apis.ois.core.v1}Prices`,
        expireMinutes: 15,
        prefetch: true
    },
    {
        url: `${appConfig.apis.ois.core.v1}ProductProperties`,
        expireMinutes: 15,
        prefetch: true
    },
    {
        url: `${appConfig.apis.ois.core.v1}Properties`,
        expireMinutes: 15,
        prefetch: true
    },
    {
        url: `${appConfig.apis.ois.core.v1}PropertyTypes`,
        expireMinutes: 15,
        prefetch: true  
    } 
]; 

export default cacheStorageUrls;
