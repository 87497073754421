import { useEffect } from "react";
import { useTranslations } from "@divide/ois-react-hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import cultureCodeAtom from "store/cultureCode";
import translationAtom from "store/translation";
import translations from "constants/translations";

export default function useTranslationStore () {
    const setTranslation = useSetRecoilState(translationAtom);
    const cultureCode = useRecoilValue(cultureCodeAtom);

    const { translation, resolved } = useTranslations(cultureCode, translations);
    
    useEffect(() => {
        if (resolved) {
            setTranslation(() => translation);
        }
    }, [translation, resolved, setTranslation]);
}