import { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";
import Checkbox from "components/FormElements/Checkbox";

export function AccessFieldCheckbox({ disabled, ...rest }) {
    const { hasAccess } = useContext(FormContext);

    disabled = disabled || !hasAccess;

    return <Checkbox disabled={disabled} {...rest} />;
}

AccessFieldCheckbox.propTypes = {
    disabled: PropTypes.bool
};

AccessFieldCheckbox.defaultProps = {
    disabled: false
};
