import { lazy, Suspense } from "react";

const Organization = lazy(() => import("pages/Organization"));
const NotFound = lazy(() => import("pages/NotFound"));

const sharedRoutes = [
    {
        key: "organizationRedirect",
        path: "organization/:name",
        inMenu: false,
        element: <Suspense fallback={<></>}><Organization /></Suspense>,
        icon: null,
        scopes: [],
        roles: [],
        children: []
    },
    {
        key: "page404",
        path: "*",
        inMenu: false,
        element: <Suspense fallback={<></>}><NotFound /></Suspense>,
        icon: null,
        scopes: [],
        roles: [],
        children: []
    },
];

export default sharedRoutes;