import { useCallback } from "react";
import styles from "./Radio.module.scss";
import PropTypes from "prop-types";

export function Radio({ label, disabled, field, form, ...rest }) {
    const handleClick = useCallback(() => {
        if (form?.setFieldTouched && field.name) {
            form.setFieldTouched(field.name);
        }
    }, [form, field]);

    return (
        <span className={`${styles["container"]} ${disabled && styles["disabled"]}`}>
            <label className={styles["label"]}>
                <input type="radio" disabled={disabled} {...field} {...rest} onClick={handleClick} />

                <span className={styles["icon"]}></span>

                {label && <span className={styles["text"]}>{label}</span>}
            </label>
        </span>
    );
}

Radio.propTypes = {
    label: PropTypes.any.isRequired,
    disabled: PropTypes.bool
};

Radio.defaultProps = {
    label: "",
    disabled: false
};
