import { lazy, Suspense } from "react";
import { BoxOpen, GearIcon } from "components/Icon";
import PageSkeleton from "parts/Page/PageSkeleton";

const DataViewerArticleDetailLayout = lazy(() => import("layout/DataViewerArticleDetailLayout"));
const DataViewerArticlesLayout = lazy(() => import("layout/DataViewerArticlesLayout"));
const ArticleDetail = lazy(() => import("pages/DataViewer/Articles/ArticleDetail"));
const Articles = lazy(() => import("pages/DataViewer/Articles"));
const DataViewer = lazy(() => import("pages/DataViewer"));
const Prices = lazy(() => import("pages/DataViewer/Articles/ArticleDetail/Prices"));
const Properties = lazy(() => import("pages/DataViewer/Articles/ArticleDetail/Properties"));
const Variants = lazy(() => import("pages/DataViewer/Articles/ArticleDetail/Variants"));

const dataViewerRoutes = [
    {
        key: "dataViewer",
        index: true,
        inMenu: false,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <DataViewer />
            </Suspense>
        ),
        icon: <BoxOpen />,
        scopes: ["*"],
        roles: [],
        children: []
    },
    {
        key: "articlesRoot",
        path: "articles",
        inMenu: true,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <DataViewerArticlesLayout />
            </Suspense>
        ),
        icon: <BoxOpen />,
        scopes: ["*"],
        roles: [],
        children: [
            {
                key: "articles",
                index: true,
                inMenu: false,
                element: (
                    <Suspense fallback={<></>}>
                        <Articles />
                    </Suspense>
                ),
                icon: <GearIcon />,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "articleDetailRoot",
                path: ":articleId",
                inMenu: false,
                element: (
                    <Suspense fallback={<></>}>
                        <DataViewerArticleDetailLayout />
                    </Suspense>
                ),
                icon: <GearIcon />,
                scopes: ["*"],
                roles: [],
                children: [
                    {
                        key: "articleDetail",
                        index: true,
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <ArticleDetail />
                            </Suspense>
                        ),
                        icon: null,
                        scopes: ["*"],
                        roles: [],
                        children: []
                    },
                    {
                        key: "variants",
                        path: "variants",
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <Variants />
                            </Suspense>
                        ),
                        icon: null,
                        scopes: ["*"],
                        roles: [],
                        children: []
                    },
                    {
                        key: "prices",
                        path: "prices",
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <Prices />
                            </Suspense>
                        ),
                        icon: null,
                        scopes: ["*"],
                        roles: [],
                        children: []
                    },
                    {
                        key: "properties",
                        path: "properties",
                        inMenu: false,
                        element: (
                            <Suspense fallback={<></>}>
                                <Properties />
                            </Suspense>
                        ),
                        icon: null,
                        scopes: ["*"],
                        roles: [],
                        children: []
                    }
                ]
            }
        ]
    }
];

export default dataViewerRoutes;