import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocalStorage } from "@divide/ois-react-hooks";
import generalStorageKeysAtom from "store/generalStorageKeys";
import cultureCodeAtom from "store/cultureCode";

export default function useCultureCodeStore () {
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom)
    const setCultureCode = useSetRecoilState(cultureCodeAtom);

    const [cultureCode] = useLocalStorage(generalStorageKeys.cultureCode, "nl-NL");

    useEffect(() => {
        setCultureCode(cultureCode);
    }, [setCultureCode, cultureCode]);
}