import { lazy, Suspense } from "react";
import { ShopIcon, SlidersIcon } from "components/Icon";
import PageSkeleton from "parts/Page/PageSkeleton";

const ConfigurationAlgorithm = lazy(() => import("pages/OrderCirculation/ConfigurationAlgorithm"));
const OrderCirculation = lazy(() => import("pages/OrderCirculation"));
const StockLocations = lazy(() => import("pages/OrderCirculation/StockLocations"));
const StockLocationsImport = lazy(() => import("pages/OrderCirculation/StockLocations/StockLocationsImport"));

const orderCirculationRoutes = [
    {
        key: "orderCirculation",
        index: true,
        inMenu: false,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <OrderCirculation />
            </Suspense>
        ),
        icon: null,
        scopes: ["*"],
        roles: [],
        children: []
    },
    {
        key: "configurationAlgorithm",
        path: "configuration-algorithm",
        inMenu: true,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <ConfigurationAlgorithm />
            </Suspense>
        ),
        icon: <SlidersIcon />,
        scopes: ["*"],
        roles: [],
        children: []
    },
    {
        key: "stockLocations",
        path: "stocklocations",
        inMenu: true,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <StockLocations />
            </Suspense>
        ),
        icon: <ShopIcon />,
        scopes: ["*"],
        roles: [],
        children: [
            {
                key: "stockLocationsImport",
                path: "import",
                inMenu: false,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <StockLocationsImport />
                    </Suspense>
                ),
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            }
        ]
    }
];

export default orderCirculationRoutes;
