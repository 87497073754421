import { lazy, Suspense } from "react";
import { BoxCircleCheckIcon } from "components/Icon";
import PageSkeleton from "parts/Page/PageSkeleton";
const Stock = lazy(() => import("pages/Stock"));
const StockManagement = lazy(() => import("pages/Stock/StockManagement"));

const stockRoutes = [
    {
        key: "stock",
        index: true,
        inMenu: false,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <Stock />
            </Suspense>
        ),
        icon: null,
        scopes: ["*"],
        roles: [],
        children: []
    },
    {
        key: "stockManagement",
        path: "stock-management",
        inMenu: true,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <StockManagement />
            </Suspense>
        ),
        icon: <BoxCircleCheckIcon />,
        scopes: ["*"],
        roles: [],
        children: []
    }
];

export default stockRoutes;