import LoginPanel from "parts/LoginPanel";
import loginbg from "assets/images/loginbg_1024x576.webp";
import styles from "./Login.module.css";

export function Login () {
    return (
        <div className={styles["login-page"]}>
            <div className={styles["bg"]}>
                <img src={loginbg} type="image/webp" alt="Login background" />
            </div>
            
            <LoginPanel />
        </div>
    );
}