import PropTypes from "prop-types";
import styles from "./Select.module.css";

export function Select({ children, className, disabled, full, size, field, form, ...rest }) {
    let classNames = [styles["select"], styles[size]];

    if (full) {
        classNames.push(styles["full"]);
    }

    if (className) {
        classNames.push(className);
    }

    return (
        <select className={classNames.join(" ")} disabled={disabled} {...field} {...rest}>
            {children}
        </select>
    );
}

Select.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    full: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    disabled: PropTypes.bool
};

Select.defaultProps = {
    full: false,
    size: "medium",
    disabled: false
};
