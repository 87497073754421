
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import circulationSettingsAtom from "store/circulationSettings";
import useGetConfiguration from "hooks/orderCirculation/useGetConfiguration";

export default function useCirculationSettingsStore () {
    const setCirculationSettings = useSetRecoilState(circulationSettingsAtom);

    const values = useGetConfiguration();

    useEffect(() => {
        setCirculationSettings(values ?? {});
    }, [values, setCirculationSettings])
}