import { useEffect } from "react";
import { useAuth0 } from "@divide/ois-react-components";
import publicRoutes from "constants/routes/publicRoutes";
import privateRoutes from "constants/routes/privateRoutes";

import { useSetRecoilState } from "recoil";
import routesAtom from "store/routes";

export default function useRoutesStore () {
    const { isAuthenticated, loading } = useAuth0();
    const setRoutes = useSetRecoilState(routesAtom);

    useEffect(() => {
        if (!loading) {
            if (isAuthenticated) {
                setRoutes(privateRoutes);
            } else {
                setRoutes(publicRoutes);
            }
        }
    }, [isAuthenticated, loading, setRoutes]);
}