
import { selector } from "recoil"

import tenantAtom from "store/tenant";
import routesAtom from "store/routes";
import cultureCodeAtom from "store/cultureCode";
import translationAtom from "store/translation";

export const appInitializedWithAppStates = selector({
    key: "appInitializedWithAppStates",
    get: ({ get }) => {
        const states = [
            { state: get(tenantAtom), type: "string" }, 
            { state: get(routesAtom), type: "object" }, 
            { state: get(cultureCodeAtom), type: "string" },
            { state: get(translationAtom), type: "function" }
        ];
        
        return states.every(({ state, type }) => validate(state, type));
    }
});

const validate = (value, type) => {
    return (value?.length > 0) && (typeof value === type);
}

