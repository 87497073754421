const env = process.env;

const APP_NAME = "ois-configutration";
const APP_PREFIX = "ois-cfg";
const APP_VERSION = env.REACT_APP_VERSION;

const appConfig = {
    name: APP_NAME,
    prefix: APP_PREFIX,
    version: APP_VERSION,
    cacheName: `${APP_PREFIX}_cache_v${APP_VERSION}`,
    supportedLanguages: ["nl-NL", "en-GB", "de-DE", "fr-FR"],
    apis: {
        ois: {
            bulk: {
                v1: env.REACT_APP_APIS_OIS_BULK
            },
            core: {
                v1: env.REACT_APP_APIS_OIS_CORE_V1,
                v2: env.REACT_APP_APIS_OIS_CORE_V2
            },
            circulation: {
                v1: env.REACT_APP_APIS_OIS_CIRCULATION_V1
            },
            userManagement: {
                v1: env.REACT_APP_APIS_OIS_USERMANAGEMENT_V1
            },
            configuration: {
                v1: env.REACT_APP_APIS_OIS_CONFIGURATION_V1
            },
            webhook: {
                v1: env.REACT_APP_APIS_OIS_WEBHOOK_V1
            }
        }
    }
};

export default appConfig;
