import { useState } from "react";
import { useRecoilValue } from "recoil";
import generalStorageKeysAtom from "store/generalStorageKeys";
import translationAtom from "store/translation";
import { organizationsMap } from "@divide/ois-react-framework/constants";
import { cookie } from "@divide/ois-react-framework/common";
import { getMainDomain, setOrganizationNameToCookies } from "@divide/ois-react-framework/helpers";
import { TextField } from "components/FormElements";
import Button from "components/Button";
import { useLogin } from "./../";
import styles from "./OrganizationSelect.module.css";
import appConfig from "constants/appConfig";

export function OrganizationSelect () {
    const translation = useRecoilValue(translationAtom);
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom);
    const { setOrganizationSelectActive, organization } = useLogin();
    const [organizationInput, setOrganizationInput] = useState("");
    const [validation, setValidation] = useState();

    const { fetchTask, isFetching } = useOrganizationChallenge();

    const handleSaveOrganization = async (e) => {
        e.preventDefault();

        try {
            const result = await fetchTask(organizationInput);

            let domain = getMainDomain();
            cookie.remove(generalStorageKeys.tenant, getMainDomain(), "/");
            cookie.remove("organizationObject");
            cookie.set("organizationObject", JSON.stringify(result), 30, domain, "/");

            window.location.reload();
        } catch (e) {
            setValidation(translation("messages.validation.invalidOrganization"));
        }
    }

    return (
        <>
            <header className={styles["header"]}>
                <h1 className={styles["title"]}>
                    {organization?.length > 0 ? (
                        <>{translation("global.changeOrganization")}</>
                    ) : (
                        <>{translation("global.enterOrganization")}</>
                    )}
                </h1>
                <p className={styles["subtitle"]}>{translation("global.enterOrganization")}</p>
            </header>
            
            <form className={styles["organization"]} onSubmit={handleSaveOrganization}>
                <TextField autoFocus as="text" className={styles["field"]} value={organizationInput} onInput={(e) => {
                    setOrganizationInput(e.currentTarget.value);
                    setValidation();
                }} />

                {validation?.length > 0 && (
                    <div className={styles["validation"]}>{validation}</div>
                )}

                <div className={styles["actions"]}>
                    <Button type="submit" fullWidth loading={isFetching}>{translation("global.save")}</Button>

                    {organization?.length > 0 && (
                        <span className={styles["back-button"]} onClick={() => setOrganizationSelectActive(false)}>{translation("global.back")}</span>
                    )}
                </div>
            </form>
        </>
    );
}

function useOrganizationChallenge() {
    const [isFetching, setIsFetching] = useState(false);

    const fetchTask = async (value) => {
        setIsFetching(true);

        const url = `${appConfig?.apis?.ois?.userManagement?.v1}Organizations/challenge?tenant=${value}`
        const response = await fetch(url);
        
        if (response.ok) {
            const result = await response.json();
            setIsFetching(false);
            return result;
        } else {
            setIsFetching(false);
            throw new Error(response.statusText);
        }
    }

    return { fetchTask, isFetching };
}