import sharedRoutes from "./sharedRoutes";
import Login from "pages/Login";

const publicRoutes = [
    { 
        key: "login",
        path: "/",
        inMenu: false,
        element: <Login />,
        icon: null,
        scopes: ["*"],
        roles: [],
        children: []
    },
    ...sharedRoutes
];

export default publicRoutes;