import { createContext } from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormikForm } from "formik";
import useUserAccess from "hooks/useUserAccess";
import { useAuth0 } from "@divide/ois-react-components";

export const FormContext = createContext();

export function Form({ children, scopes, formikProps, formProps }) {
    const { hasAccess } = useUserAccess(scopes, useAuth0, "matchAll");

    return (
        <FormContext.Provider
            value={{
                scopes,
                hasAccess
            }}
        >
            <Formik {...formikProps}>
                {typeof children === "function" ? (
                    (props) => {
                        props.hasAccess = hasAccess;

                        return (
                            <FormikForm noValidate {...formProps}>
                                {children(props)}
                            </FormikForm>
                        );
                    }
                ) : (
                    <FormikForm noValidate {...formProps}>
                        {children}
                    </FormikForm>
                )}
            </Formik>
        </FormContext.Provider>
    );
}

Form.propTypes = {
    children: PropTypes.any.isRequired,
    scopes: PropTypes.array,
    formikProps: PropTypes.shape({
        initialValues: PropTypes.any.isRequired,
        onSubmit: PropTypes.func.isRequired
    }).isRequired,
    formProps: PropTypes.object
};
