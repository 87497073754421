const env = process.env;

const auth0Config = {
    clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    audience: env.REACT_APP_AUTH0_AUDIENCE,
    domain: "divide.eu.auth0.com",
    scopes: [
        "profile",
        "openid",
        "email",
        "admin",
        "get:branches",
        "get:catalog",
        "get:circulation",
        "get:customer",
        "get:endpoints",
        "get:inventory",
        "get:inventorymatrix",
        "get:order",
        "get:prices",
        "get:settings",
        "post:circulation",
        "post:webhooks",
        "put:branches",
        "put:inventorysetting",
        "put:webhooks"
    ]
};

export default auth0Config;
