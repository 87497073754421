import { useRecoilValue } from "recoil";
import subNavigationVisibleAtom from "store/subNavigationVisible";
import { Skeleton } from "@divide/ois-react-components";
import styles from "./SubNavigationLayoutSkeleton.module.css";

export function SubNavigationLayoutSkeleton () {
    const subNavigationVisible = useRecoilValue(subNavigationVisibleAtom);

    return subNavigationVisible ? (
        <nav className={styles["sub-navigation"]}>
            <div className={styles["sub-navigation-header"]}>
                <Skeleton.Rectangle speed={1} width={175} height={30} />
            </div>
            <ul className={styles["sub-navigation-list"]}>
                <li className={styles["sub-navigation-item"]}>
                    <Skeleton.Rectangle speed={1} width={200} height={20} />
                </li>
                <li className={styles["sub-navigation-item"]}>
                    <Skeleton.Rectangle speed={1} width={160} height={20} />
                </li>
            </ul>
        </nav>
    ) : null;
}