import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import cacheStorageLocksAtom from "store/cacheStorageLocks";
import { useAuth0 } from "@divide/ois-react-components";
import { useEndpoint } from "@divide/ois-react-hooks";
import useInterval from "hooks/useInterval";
import cacheStorageUrls from "constants/cacheStorageUrls";
import appConfig from "constants/appConfig";

export default function useUpdateCacheStorage() {
    const [cacheStorageLocks, setCacheStorageLocks] = useRecoilState(cacheStorageLocksAtom);
    const { counter } = useInterval(30);
    const [urlIndex, setUrlIndex] = useState(null);
    const url = cacheStorageUrls[urlIndex]?.url;

    const handleResponseAndError = () => {
        setUrlIndex((prev) => prev + 1);
    };

    const { fetchTask } = useEndpoint(url, useAuth0, handleResponseAndError, handleResponseAndError);

    useEffect(() => {
        (async () => {
            if (urlIndex === null || !cacheStorageUrls[urlIndex]) return;

            const fetchUrl = url;

            await fetchTask("GET", {}, appConfig, true, { forceOverwriteCache: true });
            setCacheStorageLocks((list) => list.filter((url) => url !== fetchUrl));
        })();
    }, [urlIndex]);

    useEffect(() => {
        (async () => {
            const cache = await caches.open(appConfig.cacheName);

            for (let i = 0; i < cacheStorageUrls.length; i++) {
                if (!cacheStorageLocks.includes(cacheStorageUrls[i].url)) {
                    const response = await cache.match(cacheStorageUrls[i].url);

                    if (response?.headers.get("cached-time")) {
                        const nowTimestamp = new Date().getTime();
                        const cachedTime = new Date(Number(response.headers.get("cached-time")));
                        const expireTimestamp = cachedTime.setMinutes(cachedTime.getMinutes() + cacheStorageUrls[i].expireMinutes);

                        if (nowTimestamp > expireTimestamp) {
                            setCacheStorageLocks((list) => [...list, cacheStorageUrls[i].url]);
                            setUrlIndex(i);
                        }
                    }
                }
            }
        })();
    }, [counter]);
}
