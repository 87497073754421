import { useEffect, useState, useMemo } from "react";
import { useAuth0 } from "@divide/ois-react-components";
import { useEndpoint } from "@divide/ois-react-hooks";
import cacheStorageUrls from "constants/cacheStorageUrls";
import appConfig from "constants/appConfig";

export default function usePrefetchUrls() {
    const [urlIndex, setUrlIndex] = useState(0);
    const prefetchUrls = useMemo(() => cacheStorageUrls.filter((url) => url.prefetch === true), []);
    const url = prefetchUrls[urlIndex]?.url;

    const { fetchTask } = useEndpoint(url, useAuth0, () => setUrlIndex((prev) => prev + 1));

    useEffect(() => {
        if (!prefetchUrls[urlIndex]) return;
        fetchTask("GET", {}, appConfig, true);
    }, [urlIndex]);
}
