import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";

export function Tooltip({ children, content, allowHTML, animation, touch, theme, ...rest }) {
    return (
        <Tippy content={content} allowHTML={allowHTML} animation={animation} touch={touch} theme={theme} {...rest}>
            {children}
        </Tippy>
    );
}

Tooltip.propTypes = {
    children: PropTypes.any.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Tooltip.defaultProps = {
    placement: "auto",
    delay: [250, 0],
    hideOnClick: true,
    allowHTML: false,
    animation: "shift-toward",
    touch: ["hold", 250],
    theme: "tooltip"
};
