import PropTypes from "prop-types";
import styles from "./Spinner.module.css";

export function Spinner ({ children, size, color, overlay, alpha, centered, speed }) {
    const overlayClassNames = [styles["overlay"], styles[`overlay-alpha-${alpha}`]];

    if (centered) {
        overlayClassNames.push(styles["centered"]);
    }

    return overlay ? (
        <div className={overlayClassNames.join(" ")}>
            <SpinnerItem children={children} size={size} color={color} speed={speed} />
        </div>
    ) : (
        <SpinnerItem children={children} size={size} color={color} speed={speed} />
    );
}

function SpinnerItem ({ children, size, color, speed }) {
    const wrapperClassNames = [styles["wrapper"], styles[`speed-${speed}`]];
    const spinnerClassNames = [styles["spinner"], styles[size]];
    const circleClassNames = [styles["circle"], styles[`color-${color}`]];

    return (
        <div className={wrapperClassNames.join(" ")}>
            <svg className={spinnerClassNames.join(" ")} viewBox="0 0 50 50">
                <circle className={circleClassNames.join(" ")} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
            {children && <div>{children}</div>}
        </div>
    );
}


Spinner.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    color: PropTypes.oneOf(["primary", "secondary", "success", "warning", "danger", "info", "text", "muted", "light"]).isRequired,
    overlay: PropTypes.bool.isRequired,
    alpha: PropTypes.oneOf([0, 1, 3, 4, 5, 6, 7, 8, 9, 10]).isRequired,
    center: PropTypes.bool.isRequired,
    speed: PropTypes.oneOf(["fast", "normal", "slow"])
}

Spinner.defaultProps = {
    size: "medium",
    color: "primary",
    overlay: false,
    alpha: 5,
    center: false,
    speed: "normal"
}