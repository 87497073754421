import { Skeleton } from "@divide/ois-react-components";
import styles from "./DefaultLayoutSkeleton.module.css";

export function DefaultLayoutSkeleton () {
    return (
        <main className={styles["container"]}>
            <aside className={styles["navigation"]}>
                <nav className={styles["main-navigation"]}>
                    <div className={styles["main-navigation-header"]}>
                        <Skeleton.Rectangle speed={1} width={60} height={30} />
                    </div>
                    <ul className={styles["main-navigation-list"]}>
                        <li className={styles["main-navigation-item"]}>
                            <Skeleton.Rectangle speed={1} width={60} height={50} />
                        </li>
                        <li className={styles["main-navigation-item"]}>
                            <Skeleton.Rectangle speed={1} width={60} height={50} />
                        </li>
                        <li className={styles["main-navigation-item"]}>
                            <Skeleton.Rectangle speed={1} width={60} height={50} />
                        </li>
                    </ul>
                </nav>
            </aside>
        </main>
    );
}