import { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";
import Radio from "components/FormElements/Radio";

export function AccessFieldRadio({ disabled, ...rest }) {
    const { hasAccess } = useContext(FormContext);

    disabled = disabled || !hasAccess;

    return <Radio disabled={disabled} {...rest} />;
}

AccessFieldRadio.propTypes = {
    disabled: PropTypes.bool
};

AccessFieldRadio.defaultProps = {
    disabled: false
};
