import PropTypes from "prop-types";
import Tooltip from "components/Tooltip";
import { InfoIcon } from "components/Icon";
import styles from "./Label.module.css";

export function Label({ children, className, infoText, ...rest }) {
    let classNames = [styles["label"]];

    if (className) {
        classNames.push(className);
    }

    return (
        <label className={classNames.join(" ")} {...rest}>
            {infoText && (
                <span className={styles["info"]}>
                    <Tooltip content={infoText} delay={[0, 0]} placement="top">
                        <InfoIcon />
                    </Tooltip>
                </span>
            )}
            <span className={styles["text"]}>{children}</span>
        </label>
    );
}

Label.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    infoText: PropTypes.string,
};

Label.defaultProps = {
    infoText: "",
};
