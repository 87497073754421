import { useRoutes } from "react-router-dom";
import { useAuth0 } from "@divide/ois-react-components";
import { useRecoilValue } from "recoil";
import { appInitializedWithAppStates } from "store/appInitialized/appInitializedWithAppStates";
import routesAtom from "store/routes";
import useUpdateableApp from "hooks/useUpdateableApp";
import useTenantStore from "hooks/store/useTenantStore";
import useRoutesStore from "hooks/store/useRoutesStore";
import useCultureCodeStore from "hooks/store/useCultureCodeStore";
import useTranslationStore from "hooks/store/useTranslationStore";
import useSubNavigationVisibilityStore from "hooks/store/useSubNavigationVisibilityStore";
import useLocationStore from "hooks/store/useLocationStore";
import useCirculationSettingsStore from "hooks/store/useCirculationSettingsStore";
import AppSplashScreen from "./AppSplashScreen";

export function App () {
    useUpdateableApp();
    useTenantStore();
    useRoutesStore();
    useCultureCodeStore();
    useTranslationStore();
    useSubNavigationVisibilityStore();
    useLocationStore();
    useCirculationSettingsStore();
    
    const { loading } = useAuth0();
    const initialized = useRecoilValue(appInitializedWithAppStates);
    
    const routes = useRecoilValue(routesAtom);
    const element = useRoutes(routes ?? []);

    return (loading || !initialized) ? (
        <AppSplashScreen />
    ) : (
        <>{element}</> 
    );
}