import { useRecoilValue } from "recoil";
import translationAtom from "store/translation";
import { useAuth0 } from "@divide/ois-react-components";
import Button from "components/Button";
import styles from "./Authenticate.module.css";
import { useLogin } from "./../";

export function Authenticate () {
    const translation = useRecoilValue(translationAtom);

    const { loginWithRedirect } = useAuth0();
    const { setOrganizationSelectActive, organization } = useLogin();

    return (
        <>
            <h1 className={styles["title"]}>{translation("global.oisAbbr")}</h1>
            <p className={styles["subtitle"]}>{translation("global.ois")}</p>
            
            <div className={styles["organization"]}>
                <p className={styles["current-organization"]}>
                    {translation("global.currentOrganization")}: <span className={styles["organization-name"]}>{organization?.displayName}</span>
                </p>
                <span className={styles["change"]} onClick={() => setOrganizationSelectActive(true)}>{translation("global.change")}</span>
            </div>
            <Button display="block" fullWidth onClick={() => loginWithRedirect({})}>{translation("global.login")}</Button>
        </>
    )
}