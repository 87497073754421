import styles from "./Textarea.module.css";
import PropTypes from "prop-types";

export function Textarea ({defaultValue, disabled, rows, field, form, ...rest }) {
    return (
        <textarea className={styles["textarea"]} disabled={disabled} rows={rows} defaultValue={defaultValue} {...field} {...rest}></textarea>
    );
}

Textarea.propTypes = {
    defaultValue: PropTypes.any,
    rows: PropTypes.number,
    disabled: PropTypes.bool
};

Textarea.defaultProps = {
    defaultValue: "",
    rows: 2,
    disabled: false
};
