import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useRecoilValue } from "recoil";
import translationAtom from "store/translation";
import { useAuth0 } from "@divide/ois-react-components";
import { useEndpoint } from "@divide/ois-react-hooks";
import appConfig from "constants/appConfig";

const settingsUrl = `${appConfig.apis.ois.circulation.v1}Circulation/SettingsV2`;

export default function useGetConfiguration() {
    const { isAuthenticated } = useAuth0();
    const translation = useRecoilValue(translationAtom);
    const [values, setValues] = useState();

    const handleResponse = ({ content }) => {
        let resolvedContent = resolveValueTypes(content);
        setValues(resolvedContent);
    };

    const { fetchTask, error } = useEndpoint(settingsUrl, useAuth0, handleResponse);

    useEffect(() => {
        if (!isAuthenticated) return;

        fetchTask("GET", {}, appConfig, false);
    }, [isAuthenticated]);

    useEffect(() => {
        if (error) {
            toast.error(translation("messages.error.configurationValuesFetch"));
            setValues({});
        }
    }, [error, translation]);

    return values;
}

const resolveValueTypes = (content) => {
    let resolvedContent = {};

    content.forEach((field) => {
        let { Name, Value } = field;

        if (typeof Value === "string") {
            if (Value.toLowerCase() === "false" || Value.toLowerCase() === "true") {
                resolvedContent[Name] = Value?.toLowerCase() ?? "false";
            } else {
                if (!isNaN(Number(Value))) {
                    resolvedContent[Name] = Number(Value);
                } else {
                    resolvedContent[Name] = Value ?? "";
                }
            }
        } else if (typeof Value === "boolean") {
            resolvedContent[Name] = String(Value);
        } else {
            resolvedContent[Name] = Value ?? "";
        }
    });

    return resolvedContent;
};
