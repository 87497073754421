import PropTypes from "prop-types";
import styles from "./Switch.module.css";

export function Switch({ label, disabled, checked, onChange, size, field, form, ...rest }) {
    const classNames = [styles["switch"], styles[size]];

    if (disabled) {
        classNames.push(styles["disabled"]);
    }

    return (
        <label className={styles["outer"]}>
            <div className={`${classNames.join(" ")} ${checked || field?.checked ? styles["checked"] : ""}`}>
                <input type="checkbox" checked={checked || field?.checked} disabled={disabled} {...field} {...rest} />
                <span className={styles["ball"]}></span>
            </div>

            {label && <span className={styles["text"]}>{label}</span>}
        </label>
    );
}

Switch.propTypes = {
    label: PropTypes.any.isRequired,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large"])
};

Switch.defaultProps = {
    label: "",
    disabled: false,
    checked: false,
    size: "medium"
};
