const nlNl = {
    global: {
        active: "Actief",
        autoAccept: "Auto accept",
        back: "Terug",
        branch: "Vestiging",
        cancel: "Annuleren",
        change: "Wijzigen",
        changes: "Wijzigingen",
        changeLanguage: "Taal wijzigen",
        changeOrganization: "Organisatie wijzigen",
        chooseFile: "Kies bestand",
        close: "Sluiten",
        currentOrganization: "Huidige organisatie",
        enterOrganization: "Vul uw organisatie in",
        login: "Inloggen",
        logout: "Uitloggen",
        ois: "Omnichannel Integration Suite",
        oisAbbr: "OIS",
        priority: "Prioriteit",
        save: "Opslaan",
        specifyOrganization: "Organisatie opgeven",
        configurationAlgorithm: "Configuratie & algoritme",
        configuration: "Configuratie",
        algorithm: "Algoritme",
        delivery: "Levering",
        distribution: "Verdeling",
        tenant: "Tenant",
        language: "Taal",
        warehouse: "Magazijn",
        shippingLocation: "Ship from store vestiging",
        externalStockLocation: "Externe voorraadlocatie",
        maxOpenRequests: "Max. openstaande verzoeken",
        cutOffTime: "Cut off tijd",
        setup: "Instellen",
        monday: "Maandag",
        tuesday: "Dinsdag",
        wednesday: "Woensdag",
        thursday: "Donderdag",
        friday: "Vrijdag",
        saturday: "Zaterdag",
        sunday: "Zondag",
        modules: "Modules",
        noFileSelected: "Geen bestand geselecteerd",
        toDashboard: "Naar dashboard",
        toLogin: "Naar login",
        search: "Zoeken",
        downloadCsvExample: "Download voorbeeld (.csv)",
        import: "Importeren",
        importedData: (count = 0) => `${count} ${count === 1 ? "regel" : "regels"} geïmporteerd`,
        noChanges: "Er zijn momenteel geen wijzigingen",
        empty: "leeg",
        undoChanges: "Alles ongedaan maken",
        or: "of",
        navigateToType: "Navigeer naar type",
        yes: "Ja",
        no: "Nee",
        moreInformation: "Meer informatie",
        noResultsFound: "Geen resultaten gevonden",
        articleSearchInfo: "Zoek op een van de volgende kenmerken: id, articleNumber, name, posDescription of vendorArticleNumber",
        copied: "Gekopieerd!",
        copyToClipboard: "Kopiëren naar klembord",
        modifiedOn: "Gewijzigd op",
        createdOn: "Aangemaakt op",
        stockLocationType: "Voorraadlocatie type",
        shippingLocationInternal: "Ship from warehouse vestiging",
        backToOverview: "Terug naar overzicht",
        general: "Algemeen",
        usePickedStatus: "Gebruik picked status"
    },
    form: {
        label: {
            routesActive: "Routes actief",
            maxCirculationTimeframeInDays: "Maximale circulatietijd in dagen",
            excludeWarehouseExpiredCirculations: "Magazijn uitsluiten van maximale circulatietijd",
            centralizedDelivery: "Gecentraliseerde levering",
            daysToExcludeExternalBranchFromCirculation: "Dagen om externe voorraadlocaties uit te sluiten",
            hoursToExcludeBranchForPickRequestAfterReject: "Verzoek afwijzen in uren voor vestigingen",
            hoursToExcludeWarehouseForPickRequestAfterReject: "Verzoek afwijzen in uren voor het magazijn",
            filterInputByWebshopCode: "Filter verzoeken op webshopcode",
            pickRequestExpirationTimeInHours: "Aantal uur voordat een verzoek wordt doorgecirculeerd",
            yes: "Ja",
            no: "Nee",
            first: "Eerst",
            afterWhich: "Daarna",
            inCaseComplete: "indien volledig",
            everythingFrom: "alles uit",
            inCountryOf: "in land van",
            branchType: "Type vestiging",
            openRequests: "Verzoeken openstaand",
            priority: "Prioriteit",
            priorityByStockLocations: "Prioriteit 1 bij voorraadlocaties wordt als eerste gekozen",
            requestsPastDays: "Verzoeken afgelopen x dagen",
            stock: "Voorraad",
            most: "Meeste",
            least: "Minste",
            selectAnOption: "Selecteer een optie",
            newRule: "Nieuwe regel",
            searchByBranch: "Zoek op vestiging...",
            searchBySetting: "Zoek op instelling..."
        },
        info: {
            routesActive: "Bepaalt of routes actief zijn. De routes worden beheerd via de voorraadlocaties.",
            maxCirculationTimeframeInDays:
                "Bepaalt hoeveel dagen de order maximaal gecirculeerd mag worden, voordat de order gecancelled wordt.",
            excludeWarehouseExpiredCirculations:
                "Openstaande circulatie verzoeken die langer dan 24 uur open staan voor magazijn mogen wel of niet automatisch afgewezen worden.",
            centralizedDelivery: "Bepaalt of er sprake is van centrale levering.",
            daysToExcludeExternalBranchFromCirculation:
                "Bepaalt het aantal dagen (inclusief vandaag) dat externe voorraadlocaties worden uitgesloten van de circulatie.",
            stockLocationPriority: "Bepaalt de prioriteit, waarbij 1 de hoogste prioriteit is.",
            hoursToExcludeBranchForPickRequestAfterReject:
                "Het aantal uren dat een verzoek niet terug komt op een vestiging nadat deze vestiging het verzoek eerder afgewezen heeft.",
            hoursToExcludeWarehouseForPickRequestAfterReject:
                "Het aantal uren dat een verzoek niet terug komt op het magazijn nadat het magazijn het verzoek eerder afgewezen heeft.",
            filterInputByWebshopCode:
                "Geeft aan of de circulatie verzoeken gefilterd dienen te worden o.b.v. de webshopcode uit de orders.",
            pickRequestExpirationTimeInHours: "Het aantal uur voordat een verzoek op de huidige branch wordt gereject en wordt doorgecirculeerd naar een volgende branch.",
            cutOffTimeInternalShippingAgentAvailability: "Eigen vervoer is enkel beschikbaar voor centraal leveren.",
            cutOffTimeRemainingShippingAgentAvailability: "Deze vervoerder is alleen beschikbaar bij verzendpunten."
        }
    },
    components: {
        stockLocationsImport: {
            title: "vestiging",
            name: "code",
            isWarehouse: "magazijn",
            isShipping: "ship from store vestiging",
            isExternal: "externe voorraadlocatie",
            usePickedStatus: "gebruik picked status",
            autoAccept: "auto accept",
            maxOutstandingRequests: "max. openstaande verzoeken",
            priority: "prioriteit",
            active: "actief"
        }
    },
    country: {},
    language: {
        nlNL: "Nederlands",
        enGB: "English",
        frFR: "Français",
        deDE: "Deutsch"
    },
    pages: {
        page404: {
            text: "404",
            description: ""
        },
        organizationRedirect: {
            text: "Organization redirect",
            description: ""
        },
        login: {
            text: "Login",
            description: ""
        },
        root: {
            text: "Root",
            description: ""
        },
        homepage: {
            text: "Homepage",
            description: ""
        },
        dashboardRoot: {
            text: "Dashboard",
            description: ""
        },
        dashboard: {
            text: "Dashboard",
            description: "Configureer OIS door op de gewenste module te klikken."
        },
        stockRoot: {
            text: "Voorraad",
            description: "Regel hier het voorraadbeheer."
        },
        stock: {
            text: "Voorraad",
            description: "Regel hier het voorraadbeheer."
        },
        stockManagement: {
            text: "Voorraadbeheer",
            description: "Regel hier het voorraadbeheer."
        },
        orderCirculationRoot: {
            text: "Order circulatie",
            description: "Stel hier de order circulatie instellingen in."
        },
        orderCirculation: {
            text: "Order circulatie",
            description: "Stel hier de order circulatie instellingen in."
        },
        configurationAlgorithm: {
            text: "Configuratie & algoritme",
            description: "Regel de configuratie en het algoritme."
        },
        stockLocations: {
            text: "Voorraadlocaties",
            description: "Beheer de voorraadlocaties."
        },
        stockLocationsImport: {
            text: "Importeren",
            description: "Pas wijzigingen toe op bestaande vestigingen middels een csv-import."
        },
        myAccount: {
            text: "Mijn account",
            description: "Bekijk de gegevens van uw account."
        },
        changeTenant: {
            text: "Tenant wijzigen",
            description: "Wijzig de tenant voor waar u OIS wilt configureren."
        },
        changeLanguage: {
            text: "Taal wijzigen",
            description: "Wijzig de taal van het OIS configuratie dashboard."
        },
        settings: {
            text: "Instellingen",
            description: "Wijzig de algemene instellingen van OIS."
        },
        dataViewerRoot: {
            text: "Data viewer",
            description: "Bekijk diverse productinformatie en bijbehorende prijzen."
        },
        dataViewer: {
            text: "Data viewer",
            description: "Zoek binnen het productassortiment, bekijk productinformatie en bijbehorende prijzen."
        },
        articlesRoot: {
            text: "Artikelen",
            description: "Zoek naar producten en bekijk productinformatie en prijzen."
        },
        articles: {
            text: "Artikelen",
            description: "Zoek naar producten en bekijk productinformatie en prijzen."
        },
        articleDetailRoot: {
            text: "Algemeen",
            description: "Algemeen."
        },
        articleDetail: {
            text: "Artikel details",
            description: "Algemeen."
        },
        variants: {
            text: "Varianten",
            description: "Varianten."
        },
        prices: {
            text: "Prijzen",
            description: "Prijzen."
        },
        properties: {
            text: "Eigenschappen",
            description: "Eigenschappen."
        },
        monitoringRoot: {
            text: "Monitoring",
            description: "Bekijk gefaalde en geregistreerde webhooks."
        },
        monitoring: {
            text: "Monitoring",
            description: "Krijg inzicht in uw gefaalde en geregistreerde webhooks."
        },
        webhooksRoot: {
            text: "Webhooks",
            description: "Bekijk gefaalde en geregistreerde webhooks."
        },
        webhooks: {
            text: "Webhooks",
            description: "Bekijk gefaalde en geregistreerde webhooks."
        },
        webhooksFailed: {
            text: "Gefaald",
            description: ""
        },
        webhooksSubscriptions: {
            text: "Registraties",
            description: ""
        }
    },
    contextMenu: {
        retryWebhook: "Opnieuw versturen",
        showPayload: "Toon payload",
        showResponseMessage: "Toon response message"
    },
    messages: {
        confirmation: {
            cancelWebhook: "Wilt u deze webhook annuleren?",
            retryWebook: "Wilt u deze webhook opnieuw versturen?"
        },
        error: {
            branchCutOffTimesFetch: "Er is iets fout gegaan bij het ophalen van de branch cut off times.",
            catalogFetch: "Er is iets fout gegaan bij het ophalen van de catalog waardes.",
            pricesFetch: "Er is iets fout gegaan bij het ophalen van de prijzen.",
            configurationValuesFetch: "Er is iets fout gegaan bij het ophalen van de configuratie waardes.",
            distributionRulesFetch: "Er is iets fout gegaan bij het ophalen van de verdelingsregels.",
            distributionTypesFetch: "Er is iets fout gegaan bij het ophalen van de verdelingstypes.",
            deliveryRulesFetch: "Er is iets fout gegaan bij het ophalen van de verzendregels.",
            countryTypesFetch: "Er is iets fout gegaan bij het ophalen van de landtypes.",
            branchesFetch: "Er is iets fout gegaan bij het ophalen van de vestigingen.",
            branchTypesFetch: "Er is iets fout gegaan bij het ophalen van de vestigingstypes.",
            branchSettingsFetch: "Er is iets fout gegaan bij het ophalen van de vestiging instellingen.",
            shippingAgentsFetch: "Er is iets fout gegaan bij het ophalen van de expediteurs.",
            algorithmSubmit: "Er is iets fout gegaan bij het opslaan van het order circulatie algoritme.",
            configurationSubmit: "Er is iets fout gegaan bij het opslaan van het order circulatie configuratie.",
            oisSettingGroupsFetch: "Er is iets fout gegaan bij het ophalen van de OIS setting groepen.",
            oisSettingsFetch: "Er is iets fout gegaan bij het ophalen van de OIS settings per groep.",
            oisSettingsSubmit: "Er is iets fout gegaan bij het opslaan van de OIS settings.",
            webhooksSubscriptionsFetch: "Er is iets fout gegaan bij het ophalen van de webhook registraties.",
            defaultError: "Er is iets fout gegaan."
        },
        warning: {},
        info: {
            noAccessToPageTitle: "Geen toegang",
            noAccessToPageDescription:
                "U heeft geen toegang tot deze pagina. Klik op de onderstaande knop om terug te gaan naar het dashboard.",
            pageNotFoundTitle: "Pagina niet gevonden",
            pageNotFoundDescriptionDashboard:
                "De pagina die u probeert te benaderen is niet gevonden. Klik op de onderstaande knop om terug te gaan naar het dashboard.",
            pageNotFoundDescriptionLogin:
                "De pagina die u probeert te benaderen is niet gevonden. Klik op de onderstaande knop om terug te gaan naar de login pagina.",
            noDistributionRulesFound: "Geen verdelingsregels gevonden.",
            noDeliveryRulesFound: "Geen leveringsregels gevonden.",
            cutOffTimesChanges:
                "Er zijn wijzigingen aangebracht aan de cut off tijden. Druk op 'opslaan' om de wijzigingen definitief door te voeren.",
            noWebhookFailedFound: "Geen gefaalde webhooks gevonden.",
            noWebhookSubscriptionsFound: "Geen registraties gevonden."
        },
        pending: {
            retryWebhook: "Opnieuw versturen..."
        },
        success: {
            cancelledWebhook: "De webhook is geannuleerd.",
            webhookResent: "De webhook is opnieuw verzonden.",
            savedChanges: "De wijzigingen zijn opgeslagen.",
            algorithmSubmit: "Het order circulatie algoritme is succesvol opgeslagen.",
            configurationSubmit: "De order circulatie configuratie is succesvol opgeslagen.",
            importRowsFound: (count = 0) => `${count} ${count === 1 ? "regel" : "regels"} geïmporteerd.`
        },
        validation: {
            invalidOrganization: "De organisatie die u opgaf maakt geen deel uit van de Omnichannel Integration Suite.",
            invalidValue: "De waarde die u opgaf is ongeldig.",
            requiredValue: "Dit veld is verplicht.",
            numberMinimumOne: "De waarde moet minimaal 1 zijn.",
            numberMinimumZero: "De waarde moet minimaal 0 zijn.",
            numberMaxSeven: "De waarde mag maximaal 7 zijn.",
            onlyWholeNumbers: "De waarde mag alleen een geheel getal zijn.",
            priorityNumberMinimumOne: "Prioriteit: De waarde moet minimaal 1 zijn.",
            priorityOnlyWholeNumbers: "Prioriteit: De waarde mag alleen een geheel getal zijn.",
            maxOutstandingRequestsNumberMinimumOne: "Max. openstaande verzoeken: De waarde moet minimaal 1 zijn.",
            maxOutstandingRequestsOnlyWholeNumbers: "Max. openstaande verzoeken: De waarde mag alleen een geheel getal zijn.",
            codeIsRequired: "Het veld 'code' is verplicht.",
            columnError: (column) => `Tabelkoptekst '${column}' bevat spelfouten of is niet toegestaan.`,
            isWarehouseBoolean: `Magazijn: De waarde moet true of false zijn.`,
            isShippingBoolean: "Verzendvestiging: De waarde moet true of false zijn.",
            isExternalBoolean: "Extern voorraadlocatie: De waarde moet true of false zijn.",
            branchCodeString: "Code: De waarde kan niet leeg zijn.",
            usePickedStatusBoolean: "Gebruik picked status: De waarde moet true of false zijn.",
            autoAcceptBoolean: "Auto accept: De waarde moet true of false zijn.",
            maxOutstandingRequestsNumber: "Max. openstaande verzoeken: De waarde moet een nummer bevatten.",
            activeBoolean: "Actief: De waarde moet true of false zijn.",
            tooFewFields: (row) => `Het aantal tabelkopteksten komt niet overeen met het aantal cellen (regel ${row}).`,
            tooManyFields: (row) => `Het aantal cellen komt niet overeen met het aantal tabelkopteksten (regel ${row}).`
        },
        loading: {
            default: "Bezig met laden..."
        }
    }
};

export default nlNl;
