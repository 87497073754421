import { useEffect } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import tenantAtom from "store/tenant";
import generalStorageKeysAtom from "store/generalStorageKeys";
import { cookie } from "@divide/ois-react-framework/common";
import { hasProps } from "@divide/ois-react-framework/utils";
import { getMainDomain } from "@divide/ois-react-framework/helpers";
import { useGetBearerTokenPayload } from "@divide/ois-react-hooks";
import { useAuth0 } from "@divide/ois-react-components";

export default function useTenantStore () {
    const setTenant = useSetRecoilState(tenantAtom);
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom);
    const payload = useGetBearerTokenPayload(useAuth0);

    useEffect(() => {
        if (hasProps(generalStorageKeys)) {
            let domain = getMainDomain();
            let resolvedTenant = cookie.get(generalStorageKeys.tenant);

            if (!resolvedTenant) {
                let organization = cookie.get("organizationObject");

                if (organization) {
                    organization = JSON.parse(organization);

                    resolvedTenant = organization.name === "shoeby" ? `${organization}-ois` : organization.name;
                }
            }

            if (resolvedTenant) {
                cookie.set(generalStorageKeys.tenant, resolvedTenant, 365, domain, "/");
                setTenant(resolvedTenant);
            } else {
                setTenant("NA");
            }
        }
    }, [generalStorageKeys, payload, setTenant]);
}