import { lazy, Suspense } from "react";
import { WebhookIcon } from "components/Icon";
import PageSkeleton from "parts/Page/PageSkeleton";
import { Navigate } from "react-router-dom";
const Failed = lazy(() => import("pages/Monitoring/Webhooks/Failed"));
const Subscriptions = lazy(() => import("pages/Monitoring/Webhooks/Subscriptions"));
const Monitoring = lazy(() => import("pages/Monitoring"));
const WebhooksLayout = lazy(() => import("layout/WebhooksLayout"));

const monitoringRoutes = [
    {
        key: "monitoring",
        index: true,
        inMenu: false,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <Monitoring />
            </Suspense>
        ),
        scopes: ["*"],
        roles: [],
        children: []
    },
    {
        key: "webhooksRoot",
        path: "webhooks",
        inMenu: true,
        element: (
            <Suspense fallback={<PageSkeleton />}>
                <WebhooksLayout />
            </Suspense>
        ),
        icon: <WebhookIcon />,
        scopes: ["*"],
        roles: [],
        children: [
            {
                key: "webhooks",
                index: true,
                inMenu: false,
                element: <Navigate to="failed" />,
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "webhooksFailed",
                inMenu: false,
                path: "failed",
                element: (
                    <Suspense fallback={<></>}>
                        <Failed />
                    </Suspense>
                ),
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "webhooksSubscriptions",
                path: "subscriptions",
                inMenu: false,
                element: (
                    <Suspense fallback={<></>}>
                        <Subscriptions />
                    </Suspense>
                ),
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            }
        ]
    }
];

export default monitoringRoutes;
