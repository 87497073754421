import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
import sharedRoutes from "./sharedRoutes";
import { BoxIcon, DollyIcon, GearIcon, HouseIcon, ListTree, WavePulseIcon } from "components/Icon";
import DefaultLayoutSkeleton from "layout/DefaultLayout/DefaultLayoutSkeleton";
import SubNavigationLayoutSkeleton from "layout/SubNavigationLayout/SubNavigationLayoutSkeleton";
import PageSkeleton from "parts/Page/PageSkeleton";
import PrivateRoot from "parts/PrivateRoot";
import { stockRoutes, orderCirculationRoutes, monitoringRoutes, dataViewerRoutes } from "constants/routes/modules";

const DefaultLayout = lazy(() => import("layout/DefaultLayout"));
const SubNavigationLayout = lazy(() => import("layout/SubNavigationLayout"));
const ChangeLanguage = lazy(() => import("pages/ChangeLanguage"));
const ChangeTenant = lazy(() => import("pages/ChangeTenant"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const Profile = lazy(() => import("pages/Profile"));
const SettingsPage = lazy(() => import("pages/SettingsPage"));

const privateRoutes = [
    {
        key: "root",
        path: "/",
        inMenu: false,
        element: (
            <PrivateRoot>
                <Suspense fallback={<DefaultLayoutSkeleton />}>
                    <DefaultLayout />
                </Suspense>
            </PrivateRoot>
        ),
        icon: null,
        scopes: ["*"],
        roles: [],
        children: [
            {
                key: "homepage",
                index: true,
                inMenu: false,
                element: <Navigate to="/dashboard" />,
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "dashboard",
                path: "dashboard",
                inMenu: true,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <Dashboard />
                    </Suspense>
                ),
                icon: <HouseIcon />,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "stockRoot",
                path: "stock",
                inMenu: true,
                element: (
                    <Suspense fallback={<SubNavigationLayoutSkeleton />}>
                        <SubNavigationLayout />
                    </Suspense>
                ),
                icon: <BoxIcon />,
                scopes: ["*"],
                roles: [],
                children: stockRoutes
            },
            {
                key: "orderCirculationRoot",
                path: "order-circulation",
                inMenu: true,
                element: (
                    <Suspense fallback={<SubNavigationLayoutSkeleton />}>
                        <SubNavigationLayout />
                    </Suspense>
                ),
                icon: <DollyIcon />,
                scopes: ["*"],
                roles: [],
                children: orderCirculationRoutes
            },
            {
                key: "monitoringRoot",
                path: "monitoring",
                inMenu: true,
                element: (
                    <Suspense fallback={<SubNavigationLayoutSkeleton />}>
                        <SubNavigationLayout />
                    </Suspense>
                ),
                icon: <WavePulseIcon />,
                scopes: ["*"],
                roles: [],
                children: monitoringRoutes
            },
            {
                key: "dataViewerRoot",
                path: "data-viewer",
                inMenu: true,
                element: (
                    <Suspense fallback={<SubNavigationLayoutSkeleton />}>
                        <SubNavigationLayout />
                    </Suspense>
                ),
                icon: <ListTree />,
                scopes: ["*"],
                roles: [],
                children: dataViewerRoutes
            },
            {
                key: "myAccount",
                path: "my-account",
                inMenu: false,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <Profile />
                    </Suspense>
                ),
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "changeTenant",
                path: "change-tenant",
                inMenu: false,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <ChangeTenant />
                    </Suspense>
                ),
                icon: null,
                scopes: ["admin"],
                roles: [],
                children: []
            },
            {
                key: "changeLanguage",
                path: "change-language",
                inMenu: false,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <ChangeLanguage />
                    </Suspense>
                ),
                icon: null,
                scopes: ["*"],
                roles: [],
                children: []
            },
            {
                key: "settings",
                path: "settings",
                inMenu: true,
                element: (
                    <Suspense fallback={<PageSkeleton />}>
                        <SettingsPage />
                    </Suspense>
                ),
                icon: <GearIcon />,
                scopes: ["admin"],
                roles: [],
                children: []
            },
            ...sharedRoutes
        ]
    }
];

export default privateRoutes;
