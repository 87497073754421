import PropTypes from "prop-types";
import styles from "./Card.module.css";

export function Card ({ children, className, shadow, gutter, background, bordered, ...rest }) {
    let classNames = [
        styles["card"],
        styles[`background-${background}`]
    ];

    if (shadow) {
        classNames.push(styles["shadow"]);
    }

    if (className) {
        classNames.push(className);
    }

    if (bordered) {
        classNames.push(styles["bordered"]);
    }

    if (typeof gutter === "object") {
        classNames.push(styles[`gutter-top-${gutter?.top ? gutter.top : 0}`]);
        classNames.push(styles[`gutter-right-${gutter?.right ? gutter.right : 0}`]);
        classNames.push(styles[`gutter-bottom-${gutter?.bottom ? gutter.bottom : 0}`]);
        classNames.push(styles[`gutter-left-${gutter?.left ? gutter.left : 0}`]);
    } else if (typeof gutter === "number") {
        classNames.push(styles[`gutter-${gutter ? gutter : 0}`]);
    }

    return (
        <div className={classNames.join(" ")} {...rest}>
            {children}
        </div>
    );
}

Card.propTypes = {
    children: PropTypes.any.isRequired,
    className: PropTypes.string,
    shadow: PropTypes.bool.isRequired,
    gutter: PropTypes.oneOfType([
        PropTypes.shape({
            top: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7]),
            right: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7]),
            bottom: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7]),
            left: PropTypes.oneOf([0, 2, 3, 4, 5, 6, 7])
        }),
        PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6, 7])
    ]),
    background: PropTypes.oneOf(["default", "light"]).isRequired,
    bordered: PropTypes.bool.isRequired
}

Card.defaultProps = {
    shadow: false,
    gutter: 2,
    background: "default",
    bordered: false
}