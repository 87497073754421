import { useState, useEffect, useMemo } from "react";
import { useGetUserScopes } from "@divide/ois-react-hooks";

export default function useUserAccess(scopes = [], useAuth0, condition = "matchAll") {
    const { user } = useAuth0();
    const [hasAccess, setHasAccess] = useState(null);
    const userScopes = useGetUserScopes(useAuth0);

    useEffect(() => {
        if (userScopes === null) return;

        let access = false;

        if (scopes.length > 0 && userScopes.length > 0) {
            switch (condition) {
                case "matchOne":
                    access = userScopes.some((scope) => scopes.includes(scope));
                    break;
                case "matchAll":
                    access = scopes.every((scope) => userScopes.includes(scope));
                    break;
            }
        }

        if (userScopes.includes("admin")) {
            access = true;
        }

        setHasAccess(access);
    }, [user, userScopes, scopes, condition]);

    return { hasAccess };
}
