import { useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import generalStorageKeysAtom from "store/generalStorageKeys";
import subNavigationVisibleAtom from "store/subNavigationVisible";
import { storage } from "@divide/ois-react-framework/common";
import { hasProps } from "@divide/ois-react-framework/utils";

export default function useSubNavigationVisibilityStore () {
    const [subNavigationVisible, setSubNavigationVisible] = useRecoilState(subNavigationVisibleAtom);
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom);

    useEffect(() => {
        if (hasProps(generalStorageKeys)) {
            let storageValue = storage.get(generalStorageKeys.subNavigationExpanded); 
            let isExpandedValue;
            
            if (storageValue === "true") {
                isExpandedValue = true;
            } else {
                isExpandedValue = false;
            }

            setSubNavigationVisible(isExpandedValue);
        }
    }, [generalStorageKeys, setSubNavigationVisible]);

    useEffect(() => {
        if (generalStorageKeys?.subNavigationExpanded) {
            storage.set(generalStorageKeys.subNavigationExpanded, subNavigationVisible);
        }
    }, [subNavigationVisible, generalStorageKeys?.subNavigationExpanded]);
}