import PropTypes from "prop-types";
import styles from "./RadioGroup.module.css";

export function RadioGroup({ children, inline }) {
    let classNames = [styles["container"]];

    if (inline) {
        classNames.push(styles["inline"]);
    }

    return <div className={classNames.join(" ")}>{children}</div>
}

RadioGroup.propTypes = {
    children: PropTypes.any.isRequired,
    inline: PropTypes.bool.isRequired
}

RadioGroup.defaultProps = {
    inline: true
}
