import { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";
import TextField from "components/FormElements/TextField";

export function AccessFieldTextField({ disabled, ...rest }) {
    const { hasAccess } = useContext(FormContext);

    disabled = disabled || !hasAccess;

    return <TextField disabled={disabled} {...rest} />;
}

AccessFieldTextField.propTypes = {
    disabled: PropTypes.bool
};

AccessFieldTextField.defaultProps = {
    disabled: false
};
