const deDe = {
    global: {
        active: "Aktiv",
        autoAccept: "Automatisch akzeptieren",
        back: "Vorherige",
        branch: "Speichern",
        cancel: "Absagen",
        change: "Ändern",
        changes: "Änderungen",
        changeLanguage: "Sprache ändern",
        changeOrganization: "Organisation ändern",
        chooseFile: "Datei wählen",
        close: "Schließen",
        currentOrganization: "Aktuelle Organisation",
        enterOrganization: "Geben Sie Ihre Organisation ein",
        login: "Anmeldung",
        logout: "Ausloggen",
        ois: "Omnichannel Integration Suite",
        oisAbbr: "OIS",
        priority: "Priorität",
        save: "Speichern",
        specifyOrganization: "Organisation angeben",
        configurationAlgorithm: "Konfiguration & Algorithmus",
        configuration: "Aufbau",
        algorithm: "Algorithmus",
        delivery: "Livraison",
        distribution: "Verteilung",
        tenant: "Tenant",
        language: "Sprache",
        warehouse: "Lager",
        shippingLocation: "Versandort",
        externalStockLocation: "Externer Lagerort",
        maxOpenRequests: "Max. offene Anfragen",
        cutOffTime: "Abschaltzeit",
        setup: "Konfiguration",
        monday: "Montag",
        tuesday: "Dienstag",
        wednesday: "Mittwoch",
        thursday: "Donnerstag",
        friday: "Freitag",
        saturday: "Samstag",
        sunday: "Sonntag",
        modules: "Module",
        noFileSelected: "Keine Datei ausgewählt",
        toDashboard: "Zum Armaturenbrett",
        toLogin: "Zum Einloggen",
        search: "Suche",
        downloadCsvExample: "Beispiel herunterladen (.csv)",
        import: "Importieren",
        importedData: (count = 0) => `${count} ${count === 1 ? "Zeile" : "Zeilen"} importiert`,
        noChanges: "Aktuell gibt es keine Änderungen",
        empty: "leer",
        undoChanges: "Alles rückgängig machen",
        or: "oder",
        navigateToType: "Navigieren Sie nach Typ",
        yes: "Ja",
        no: "Nein",
        moreInformation: "Mehr Informationen",
        noResultsFound: "Keine Ergebnisse gefunden",
        articleSearchInfo:
            "Suchen Sie nach einem der folgenden Attribute: ID, ArticleNumber, Name, PosDescription oder VendorArticleNumber",
        copied: "Kopiert!",
        copyToClipboard: "In die Zwischenablage kopieren",
        modifiedOn: "Geändert am",
        createdOn: "Erstellt am",
        stockLocationType: "Lagerorttyp",
        shippingLocationInternal: "Versandort aus Lagerstandort",
        backToOverview: "Zurück zur Übersicht",
        general: "Allgemein",
        usePickedStatus: "Status Abgeholt verwenden"
    },
    form: {
        label: {
            routesActive: "Routen aktiv",
            maxCirculationTimeframeInDays: "Maximale Umlaufzeit in Tagen",
            excludeWarehouseExpiredCirculations: "Lager von maximaler Umlaufzeit ausschließen",
            centralizedDelivery: "Livraison centralisee",
            daysToExcludeExternalBranchFromCirculation: "Tage zum Ausschließen externer Lagerorte",
            hoursToExcludeBranchForPickRequestAfterReject: "Refuser la demande en heures pour les établissements",
            hoursToExcludeWarehouseForPickRequestAfterReject: "Anfrage in Stunden für das Lager ablehnen",
            filterInputByWebshopCode: "Filtern Sie Anfragen nach Webshop-Code",
            pickRequestExpirationTimeInHours: "Ablaufzeit der Anfrage in Stunden",
            yes: "Ja",
            no: "Nein",
            first: "Zuerst",
            afterWhich: "Dann",
            inCaseComplete: "wenn vollständig",
            everythingFrom: "alles aus",
            inCountryOf: "im Land von",
            branchType: "Standorttyp",
            openRequests: "Anfragen ausstehend",
            priority: "Priorität",
            priorityByStockLocations: "Priorität 1 in Lagerplätzen wird zuerst ausgewählt",
            requestsPastDays: "Anfragen in den letzten x tagen",
            stock: "Lager",
            most: "Die meisten",
            least: "Am wenigsten",
            selectAnOption: "Wähle eine Option",
            newRule: "Neue Regel",
            searchByBranch: "Suche nach Standort...",
            searchBySetting: "Suche nach Einstellung..."
        },
        info: {
            routesActive: "Bestimmt, ob Routen aktiv sind. Die Touren werden über die Lagerorte verwaltet.",
            maxCirculationTimeframeInDays:
                "Legt die maximale Anzahl von Tagen fest, die die Bestellung im Umlauf sein darf, bevor er storniert wird.",
            excludeWarehouseExpiredCirculations:
                "Offene Ausleihanforderungen, die länger als 24 Stunden für das Lager offen sind, können automatisch abgelehnt werden oder nicht.",
            centralizedDelivery: "Legt fest, ob es eine zentrale Zustellung gibt.",
            daysToExcludeExternalBranchFromCirculation:
                "Legt die Anzahl der Tage fest (einschließlich des heutigen Tages), die externe Inventarstandorte von der Ausleihe ausgeschlossen sind.",
            stockLocationPriority: "Legen Sie die Priorität fest, wobei 1 die höchste Priorität ist.",
            hoursToExcludeBranchForPickRequestAfterReject:
                "Die Anzahl der Stunden, die eine Anfrage nicht an einen Standort zurückgegeben wird, nachdem dieser Standort die Anfrage zuvor abgelehnt hat.",
            hoursToExcludeWarehouseForPickRequestAfterReject:
                "Die Anzahl der Stunden, in denen eine Anforderung nicht an das Lager zurückgegeben wird, nachdem das Lager die Anforderung zuvor abgelehnt hat.",
            filterInputByWebshopCode:
                "Darüber hinaus basiert die Zirkulationsmethode auf der Filterfunktion und der Boutique-Code-Funktion.",
            pickRequestExpirationTimeInHours: "Die Anzahl der Stunden, bevor ein Antrag am aktuellen Standort abgelehnt und an den nächsten Standort weitergeleitet wird.",
            cutOffTimeInternalShippingAgentAvailability: "Eigener Transport steht nur bei zentraler Zustellung zur Verfügung.",
            cutOffTimeRemainingShippingAgentAvailability: "Dieser Spediteur ist nur an Versandstellen verfügbar."
        }
    },
    components: {
        stockLocationsImport: {
            title: "zweig",
            name: "code",
            isWarehouse: "lager",
            isShipping: "versandort",
            isExternal: "externer lagerort",
            usePickedStatus: "status Abgeholt verwenden",
            autoAccept: "automatisch akzeptieren",
            maxOutstandingRequests: "max. offene Anfragen",
            priority: "priorität",
            active: "aktiv"
        }
    },
    country: {},
    language: {
        nlNL: "Nederlands",
        enGB: "English",
        frFR: "Français",
        deDE: "Deutsch"
    },
    pages: {
        page404: {
            text: "404",
            description: ""
        },
        organizationRedirect: {
            text: "Organisationsumleitung",
            description: ""
        },
        login: {
            text: "Anmeldung",
            description: ""
        },
        root: {
            text: "Wurzel",
            description: ""
        },
        homepage: {
            text: "Startseite",
            description: ""
        },
        dashboardRoot: {
            text: "Armaturenbrett",
            description: ""
        },
        dashboard: {
            text: "Armaturenbrett",
            description: "Konfigurieren Sie OIS, indem Sie auf das gewünschte Modul klicken."
        },
        stockRoot: {
            text: "Lager",
            description: "Lagerverwaltung hier vereinbaren."
        },
        stock: {
            text: "Lager",
            description: "Lagerverwaltung hier vereinbaren."
        },
        stockManagement: {
            text: "Bestandsverwaltung",
            description: "Lagerverwaltung hier vereinbaren."
        },
        orderCirculationRoot: {
            text: "Umlauf bestellen",
            description: "Legen Sie hier die Bestellauflagen fest."
        },
        orderCirculation: {
            text: "Umlauf bestellen",
            description: "Legen Sie hier die Bestellauflagen fest."
        },
        configurationAlgorithm: {
            text: "Konfiguration & Algorithmus",
            description: "Steuern Sie die Konfiguration und den Algorithmus."
        },
        stockLocations: {
            text: "Lagerstandorte",
            description: "Verwalten Sie die Lagerorte."
        },
        stockLocationsImport: {
            text: "Importieren",
            description: "Übernehmen Sie Änderungen an bestehenden Standorten durch einen CSV-Import."
        },
        myAccount: {
            text: "Mein Konto",
            description: "Zeigen Sie Ihre Kontodetails an."
        },
        changeTenant: {
            text: "Mieter wechseln",
            description: "Ändern Sie den Mandanten, für den Sie OIS konfigurieren möchten."
        },
        changeLanguage: {
            text: "Sprache ändern",
            description: "Ändern Sie die Sprache des OIS-Konfigurations-Dashboards."
        },
        settings: {
            text: "Einstellungen",
            description: "Ändern Sie die allgemeinen Einstellungen von OIS."
        },
        dataViewerRoot: {
            text: "Data viewer",
            description: "Sehen Sie sich verschiedene Produktinformationen und zugehörige Preise an."
        },
        dataViewer: {
            text: "Data viewer",
            description: "Suchen Sie innerhalb des Produktsortiments, sehen Sie sich Produktinformationen und zugehörige Preise an."
        },
        articlesRoot: {
            text: "Artikel",
            description: "Nach Produkten suchen und Produktinformationen und Preise anzeigen."
        },
        articles: {
            text: "Artikel",
            description: "Nach Produkten suchen und Produktinformationen und Preise anzeigen."
        },
        articleDetailRoot: {
            text: "Allgemein",
            description: "Allgemein."
        },
        articleDetail: {
            text: "Artikeldetails",
            description: "Allgemein."
        },
        variants: {
            text: "Varianten",
            description: "Varianten."
        },
        prices: {
            text: "Preise",
            description: "Preise."
        },
        properties: {
            text: "Eigenschaften",
            description: "Eigenschaften."
        },
        monitoringRoot: {
            text: "Überwachung",
            description: "Fehlgeschlagene und registrierte Webhooks anzeigen."
        },
        monitoring: {
            text: "Überwachung",
            description: "Gain insight into your failed and registered webhooks."
        },
        webhooksRoot: {
            text: "Webhooks",
            description: "Fehlgeschlagene und registrierte Webhooks anzeigen."
        },
        webhooks: {
            text: "Webhooks",
            description: "Fehlgeschlagene und registrierte Webhooks anzeigen."
        },
        webhooksFailed: {
            text: "Fehlgeschlagen",
            description: ""
        },
        webhooksSubscriptions: {
            text: "Abonnements",
            description: ""
        }
    },
    contextMenu: {
        retryWebhook: "Erneut senden",
        showPayload: "Nutzlast anzeigen",
        showResponseMessage: "Antwortnachricht anzeigen"
    },
    messages: {
        confirmation: {
            cancelWebhook: "Möchten Sie diesen Webhook abbrechen?",
            retryWebook: "Möchten Sie diesen Webhook erneut senden?"
        },
        error: {
            branchCutOffTimesFetch: "Beim Abrufen der Abschaltzeitwerte für den Zweig ist ein Fehler aufgetreten.",
            CatalogFetch: "Beim Abrufen der Katalogwerte ist ein Fehler aufgetreten.",
            pricesFetch: "Beim Abrufen der Preise ist ein Fehler aufgetreten.",
            configurationValuesFetch: "Beim Abrufen der Konfigurationswerte ist etwas schief gelaufen.",
            distributionRulesFetch: "Beim Abrufen der Verteilungsregeln ist etwas schief gelaufen.",
            distributionTypesFetch: "Beim Abrufen der Verteilungstypen ist etwas schief gelaufen.",
            deliveryRulesFetch: "Beim Abrufen der Versandregeln ist etwas schief gelaufen.",
            countryTypesFetch: "Beim Abrufen der Ländertypen ist etwas schief gelaufen.",
            branchesFetch: "Beim Abrufen der Stores ist etwas schief gelaufen.",
            branchTypesFetch: "Beim Abrufen der Zweigtypen ist etwas schief gelaufen.",
            branchSettingsFetch: "Beim Abrufen der Zweigeinstellungen ist etwas schief gelaufen.",
            shippingAgentsFetch: "Beim Abrufen von Spediteuren ist etwas schief gelaufen.",
            algorithmSubmit: "Beim Speichern des Bestellumlaufalgorithmus ist etwas schief gelaufen.",
            configurationSubmit: "Beim Speichern der Bestellumlaufkonfiguration ist etwas schief gelaufen.",
            oisSettingGroupsFetch: "Beim Abrufen der OIS-Einstellungsgruppen ist ein Fehler aufgetreten.",
            oisSettingsFetch: "Beim Abrufen der OIS-Einstellungen nach Gruppe ist etwas schief gelaufen.",
            oisSettingsSubmit: "Beim Speichern der OIS-Einstellungen ist etwas schief gelaufen.",
            defaultError: "Etwas ist schief gelaufen."
        },
        warning: {},
        info: {
            noAccessToPageTitle: "Kein Zugang",
            noAccessToPageDescription:
                "Sie haben keinen Zugriff auf diese Seite. Klicken Sie auf die Schaltfläche unten, um zum Dashboard zurückzukehren.",
            pageNotFoundTitle: "Seite nicht gefunden",
            pageNotFoundDescriptionDashboard:
                "Die Seite, auf die Sie zugreifen möchten, wurde nicht gefunden. Klicken Sie auf die Schaltfläche unten, um zum Dashboard zurückzukehren.",
            pageNotFoundDescriptionLogin:
                "Die Seite, auf die Sie zugreifen möchten, wurde nicht gefunden. Klicken Sie auf die Schaltfläche unten, um zur Anmeldeseite zurückzukehren.",
            noDistributionRulesFound: "Keine Verteilungsregeln gefunden.",
            noDeliveryRulesFound: "Keine Lieferregeln gefunden.",
            cutOffTimesChanges:
                "Die Annahmeschlusszeiten wurden geändert. Drücken Sie 'Speichern', um die Änderungen endgültig zu übernehmen.",
            noWebhookFailedFound: "Keine fehlgeschlagenen Webhooks gefunden.",
            noWebhookSubscriptionsFound: "Keine Anmeldungen gefunden."
        },
        pending: {
            retryWebhook: "Erneut senden..."
        },
        success: {
            cancelledWebhook: "Der Webhook wurde erneut gesendet.",
            webhookResent: "Der Webhook wurde erneut gesendet.",
            savedChanges: "Die Änderungen werden gespeichert.",
            algorithmSubmit: "Der Bestellumlaufalgorithmus wurde erfolgreich gespeichert.",
            configurationSubmit: "Die Auftragsumlaufkonfiguration wurde erfolgreich gespeichert.",
            importRowsFound: (count = 0) => `${count} ${count === 1 ? "Zeile" : "Zeilen"} importiert.`
        },
        validation: {
            invalidOrganization: "Die von Ihnen angegebene Organisation ist nicht Teil der Omnichannel Integration Suite.",
            invalidValue: "Der eingegebene Wert ist ungültig.",
            requiredValue: "Dieses feld ist erforderlich.",
            numberMinimumOne: "Der wert muss mindestens 1 sein.",
            numberMinimumZero: "Der wert muss mindestens 0 sein.",
            numberMaxSeven: "Der wert darf maximal 7 sein.",
            onlyWholeNumbers: "Der wert kann nur eine ganze Zahl sein.",
            priorityNumberMinimumOne: "Priorität: Der wert muss mindestens 1 sein.",
            priorityOnlyWholeNumbers: "Priorität: Der wert kann nur eine ganze Zahl sein.",
            maxOutstandingRequestsNumberMinimumOne: "Max. offene Anfragen: Der wert muss mindestens 1 sein.",
            maxOutstandingRequestsOnlyWholeNumbers: "Max. offene Anfragen: Der wert kann nur eine ganze Zahl sein.",
            codeIsRequired: "Das Feld 'Code' ist obligatorisch.",
            columnError: (column) => `Tabellenkopf '${column}' enthält Rechtschreibfehler oder ist nicht erlaubt.`,
            isWarehouseBoolean: `Lager: Der Wert muss 'true' oder 'false' sein.`,
            isShippingBoolean: "Versandort: Der Wert muss 'true' oder 'false' sein.",
            isExternalBoolean: "Externer Lagerort: Der Wert muss 'true' oder 'false' sein.",
            branchCodeString: "Code: Der Wert darf nicht leer sein.",
            usePickedStatusBoolean: "Status Abgeholt verwenden: Der Wert muss 'true' oder 'false' sein.",
            autoAcceptBoolean: "Automatisch akzeptieren: Der Wert muss 'true' oder 'false' sein.",
            maxOutstandingRequestsNumber: "Max. offene Anfragen: Der Wert muss eine Zahl sein.",
            activeBoolean: "Aktiv: Der Wert muss 'true' oder 'false' sein.",
            tooFewFields: (row) => `Die Anzahl der Tabellenköpfe stimmt nicht mit der Anzahl der Zellen überein (Regel ${row}).`,
            tooManyFields: (row) => `Die Anzahl der Zellen stimmt nicht mit der Anzahl der Tabellenköpfe überein (Regel ${row}).`
        },
        loading: {
            default: "Wird geladen..."
        }
    }
};

export default deDe;
