
import { toast } from "react-toastify";
import { useServiceWorker } from "@divide/ois-react-hooks";
import appConfig from "constants/appConfig";

export default function useUpdateableApp() {
    const toastText = "Er is een nieuwe update beschikbaar, klik op deze melding om de update te installeren.";
    
    const handleOnUpdate = () => {
        toast.info(toastText, {
            position: "top-right",
            onClose: () => {
                clearCache();
                window.location.href = "/";
            }
        });
    }

    useServiceWorker(handleOnUpdate);
}

const clearCache = () => {
    let dataCacheName = appConfig.cacheName;
    let fileCacheName = `workbox-precache-v2-${window.location.origin}/`;
    
    caches.delete(dataCacheName);
    caches.delete(fileCacheName);
}
