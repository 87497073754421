import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@divide/ois-react-components";
import { ToastContainer } from "react-toastify";
import { RecoilRoot } from "recoil";
import { resolveOrganization } from "helpers/resolveOrganization";
import auth0Redirection from "helpers/auth0Redirection";
import auth0Config from "constants/auth0Config";
import App from "parts/App";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-toward.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/variables.css";
import "./assets/css/base.css";
import "./assets/css/tippyThemes.css";
import "./assets/css/reactToastify.css";

const container = document.querySelector("#root");
const root = createRoot(container);
const organizationId = resolveOrganization();

const auth0ProviderProps = {
    domain: auth0Config.domain,
    audience: auth0Config.audience,
    client_id: auth0Config.clientId,
    scope: auth0Config.scopes.join(" "),
    redirect_uri: window.location.origin,
    onRedirectCallback: auth0Redirection,
    organization: organizationId ? organizationId : undefined
}

root.render(
    <RecoilRoot>
        <Auth0Provider {...auth0ProviderProps}>
            <BrowserRouter>
                <ToastContainer limit={5} />
                <App />
            </BrowserRouter>
        </Auth0Provider>
    </RecoilRoot>
);