import { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";
import Switch from "components/FormElements/Switch";

export function AccessFieldSwitch({ disabled, ...rest }) {
    const { hasAccess } = useContext(FormContext);

    disabled = disabled || !hasAccess;

    return <Switch disabled={disabled} {...rest} />;
}

AccessFieldSwitch.propTypes = {
    disabled: PropTypes.bool
};

AccessFieldSwitch.defaultProps = {
    disabled: false
};
