import { NavLink } from "react-router-dom";
import { Spinner } from "components/Loaders";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";

export function Button({
    children,
    className = "",
    to = null,
    type = "button",
    disabled = false,
    display = "inline-block",
    variant = "solid",
    density = "default",
    color = "primary",
    shape = "square",
    size = "medium",
    iconLeft: IconLeft = null,
    iconRight: IconRight = null,
    fullWidth = false,
    underline = false,
    autoHeight = false,
    loading = false,
    ...rest
}) {
    let cssClass = [styles["button"], styles[variant], styles[density], styles[display], styles[color], styles[shape], styles[size]];

    if (fullWidth) {
        cssClass.push(styles["full-width"]);
    }

    if (loading) {
        disabled = true;
    }

    if (disabled) {
        cssClass.push(styles["disabled"]);
    }

    if (underline) {
        cssClass.push(styles["underline"]);
    }

    if (autoHeight) {
        cssClass.push(styles["auto-height"]);
    }

    let extraClasses = className.split(" ").map((key) => {
        if (styles.hasOwnProperty(key)) {
            return styles[key];
        } else {
            return key;
        }
    });

    cssClass = [...cssClass, ...extraClasses];

    const Tag = to ? NavLink : "button";

    if (Tag === "button") {
        rest.type = type;
    }

    return (
        <Tag to={to} disabled={disabled} className={cssClass.join(" ")} {...rest}>
            {loading && (
                <div className={styles["loader"]}>
                    <Spinner size="small" color="light" />
                </div>
            )}

            <span className={styles["content-wrapper"]}>
                {IconLeft && (
                    <span className={styles["icon-left"]}>
                        <IconLeft />
                    </span>
                )}

                <span className={styles["content"]}>{children}</span>

                {IconRight && (
                    <span className={styles["icon-right"]}>
                        <IconRight />
                    </span>
                )}
            </span>
        </Tag>
    );
}

Button.propTypes = {
    children: PropTypes.any,
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    display: PropTypes.oneOf(["inline-block", "block", "flex-inline", "flex"]),
    variant: PropTypes.oneOf(["text", "solid", "link"]),
    density: PropTypes.oneOf(["default", "compact"]),
    color: PropTypes.oneOf(["primary", "secondary", "tertiary", "quaternary", "success", "warning", "danger", "info"]),
    shape: PropTypes.oneOf(["square", "pill", "circle"]),
    size: PropTypes.oneOf(["xsmall", "small", "medium", "large"])
};
