import { createContext, useContext, useState, useEffect } from "react";
import { useRecoilValue } from "recoil";
import generalStorageKeysAtom from "store/generalStorageKeys";
import { cookie } from "@divide/ois-react-framework/common";
import Card from "components/Card";
import Authenticate from "./Authenticate";
import OrganizationSelect from "./OrganizationSelect";
import styles from "./LoginPanel.module.css";

const LoginContext = createContext();

export function LoginPanel () {
    const generalStorageKeys = useRecoilValue(generalStorageKeysAtom);
    const [organizationSelectActive, setOrganizationSelectActive] = useState(false);
    const [organization, setOrganization] = useState();

    useEffect(() => {
        let storedOrganization = cookie.get("organizationObject");

        if (storedOrganization?.length) {
            setOrganization(JSON.parse(storedOrganization));
        } else {
            setOrganizationSelectActive(true);
        }
    }, [generalStorageKeys]);

    return (
        <LoginContext.Provider value={{
            setOrganizationSelectActive,
            organization
        }}>
            <Card className={styles["card"]} gutter={7} shadow>
                {organizationSelectActive ? (
                    <OrganizationSelect />
                ) : (
                    <Authenticate />
                )}
            </Card>
        </LoginContext.Provider>
    );
}

export const useLogin = () => useContext(LoginContext);