import { useContext } from "react";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";
import Select from "components/FormElements/Select";

export function AccessFieldSelect({ children, disabled, ...rest }) {
    const { hasAccess } = useContext(FormContext);

    disabled = disabled || !hasAccess;

    return <Select disabled={disabled} {...rest}>{children}</Select>;
}

AccessFieldSelect.propTypes = {
    disabled: PropTypes.bool
};

AccessFieldSelect.defaultProps = {
    disabled: false
};
