import styles from "./PageSkeleton.module.css";
import { Skeleton } from "@divide/ois-react-components";

export function PageSkeleton () {
    return (
        <section className={styles["wrapper"]}>
            <div className={styles["breadcrumbs"]}>
                <Skeleton.Rectangle width={320} height={20} />
            </div>
            <div className={styles["content"]}>
                <Skeleton.Rectangle width={250} height={40} />
            </div>
        </section>
    );
}