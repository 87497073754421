import { RotateLeftIcon, TrashCanIcon } from "components/Icon";
import PropTypes from "prop-types";
import styles from "./RemoveUndo.module.scss";

export function RemoveUndo ({ disabled, checked, field, form, ...rest }) {
    return (
        <span className={`${styles["container"]} ${disabled && styles["disabled"]}`}>
            <label className={styles["label"]}>
                <input type="checkbox" disabled={disabled} checked={checked} {...field} {...rest} />

                {(checked || field.value) ? (
                    <span className={styles["remove-icon"]}><TrashCanIcon /></span>
                ) : (
                    <span className={styles["undo-icon"]}><RotateLeftIcon /></span>
                )}
            </label>
        </span>
    );
}

RemoveUndo.propTypes = {
    disabled: PropTypes.bool.isRequired,
    checked: PropTypes.bool,
    field: PropTypes.object,
}

RemoveUndo.defaultProps = {
    disabled: false,
    checked: false,
    field: {}
}