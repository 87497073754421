import PropTypes from "prop-types";
import styles from "./TextField.module.css";

export function TextField({ type, className, maxWidth, full, size, disabled, field, form, ...rest }) {
    let classNames = [styles["input"], styles[size]];
    let style = {};

    if (full) {
        classNames.push(styles["full"]);
    }

    if (className) {
        classNames.push(className);
    }

    if (maxWidth) {
        style.maxWidth = maxWidth;
    }

    return <input type={type} className={classNames.join(" ")} disabled={disabled} style={style} {...field} {...rest} />;
}

TextField.propTypes = {
    type: PropTypes.oneOf(["date", "datetime-local", "email", "month", "number", "password", "search", "tel", "text", "time", "url", "week"]).isRequired,
    className: PropTypes.string,
    maxWidth: PropTypes.string,
    full: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(["small", "medium", "large"]).isRequired,
    disabled: PropTypes.bool
};

TextField.defaultProps = {
    maxWidth: null,
    type: "text",
    full: false,
    size: "medium",
    disabled: false
};
